import { Card, Paper } from "@material-ui/core";
import {
  Box,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { TabContext, TabPanel } from "@material-ui/lab";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import axios from "axios";
import { debt_funds, hybrid_funds } from "../../../Data/data";

const SchemeDetails = ({ accessToken }) => {
  const params = useParams();
  const [value, setValue] = useState("1");
  const [chartTab, setChartTab] = useState("1");

  const [scheme, setScheme] = useState<any>();

  const [mapAllocationChart, setMapAllocationChart] = useState<any>([]);

  const [assetAllocationChart, setAssetAllocationChart] = useState<any>([]);

  const [ratingAllocationChart, setRatingAllocationChart] = useState<any>([]);

  const year_returns = [2019, 2020, 2021, 2022, 2023];

  const handleFundChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const backgroundColor = [
    "#009DCF", // Light Blue
    "#3E77B6", // Medium Blue
    "#6C85BD", // Medium Light Blue
    "#5B61A1", // Dark Blue
    "#3474A7", // Deep Sky Blue
    "#1C5B8A", // Royal Blue
    "#073C69", // Navy Blue
    "#89A4C5", // Light Steel Blue
    "#2E5289", // Steel Blue
    "#4B618C", // Slate Blue
    "#B0C7DC", // Light Pastel Blue
  ];

  const handleChartChange = (event: React.SyntheticEvent, newValue: string) => {
    setChartTab(newValue);
  };

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_HOST +
          "v1/funds/schemes?id=" +
          params.scheme_id,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => {
        setScheme(res.data.scheme);

        const scheme = res.data.scheme;

        if (scheme.allocation) {
          let keys = Object.keys(scheme.allocation);
          let values = Object.values(scheme.allocation);

          const labels = keys.map((value) => {
            return value
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");
          });

          setMapAllocationChart({
            labels: labels,
            datasets: [
              {
                data: values,
                backgroundColor: backgroundColor,
              },
            ],
          });
        }

        if (scheme.asset_type_allocation) {
          let keys = Object.keys(scheme.asset_type_allocation);
          let values = Object.values(scheme.asset_type_allocation);

          const labels = keys.map((value) => {
            return value
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");
          });

          setAssetAllocationChart({
            labels: labels,
            datasets: [
              {
                data: values,
                backgroundColor: backgroundColor,
              },
            ],
          });
        }

        if (scheme.rating_wise_allocation) {
          let keys = Object.keys(scheme.rating_wise_allocation);
          let values = Object.values(scheme.rating_wise_allocation);

          const labels = keys.map((value) => {
            return value
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");
          });

          setRatingAllocationChart({
            labels: labels,
            datasets: [
              {
                data: values,
                backgroundColor: backgroundColor,
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const optionsPie = {
    maintainAspectRaio: false,
    aspectRaio: 1,
    plugins: {
      legend: {
        position: "left" as const,
        labels: {
          font: {
            family: "Montserrat",
          },
        },
      },
      datalabels: {
        color: [
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
        ],
      },
    },
  };

  const convertNumber = (value: any) => {
    return <Typography>{value ? `${value.toFixed(2)}%` : "-"}</Typography>;
  };

  return (
    scheme && (
      <div style={{ padding: "20px" }}>
        <Box>
          <Card>
            <CardContent>
              <Typography variant="h5">{scheme.name}</Typography>
            </CardContent>
          </Card>
          <Card>
            <CardContent sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  justifyContent: "left",
                }}
              >
                <Typography variant="h6">Fund Returns</Typography>
                {/* <Typography color="gray">as on 07 Mar 2024</Typography> */}
              </Box>
              <TabContext value={value}>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleFundChange}
                    sx={{ background: "#E8E9EB", marginTop: 3 }}
                  >
                    <Tab label="Short term" value="1" />
                    <Tab label="Long term" value="2" />
                    <Tab label="Annual" value="3" />
                  </Tabs>
                </Box>
                <TabPanel value="1">
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: 2, paddingLeft: 2 }}
                  >
                    <Grid xs={1}>
                      <Typography color="gray">1 Month</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">3 Month</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">6 Month</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">1 Year</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">YTD</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: 2, paddingLeft: 2 }}
                  >
                    <Grid xs={1}>
                      {convertNumber(scheme.returns.one_month)}
                    </Grid>
                    <Grid xs={1}>
                      {convertNumber(scheme.returns.three_months)}
                    </Grid>
                    <Grid xs={1}>
                      {convertNumber(scheme.returns.six_months)}
                    </Grid>
                    <Grid xs={1}>{convertNumber(scheme.returns.one_year)}</Grid>
                    <Grid xs={1}>{convertNumber(scheme.returns.ytd)}</Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: 2, paddingLeft: 2 }}
                  >
                    <Grid xs={1}>
                      <Typography color="gray">2 Years</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">3 Years</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">5 Years</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">Since Inception</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: 2, paddingLeft: 2 }}
                  >
                    <Grid xs={1}>
                      {convertNumber(scheme.returns.two_years)}
                    </Grid>
                    <Grid xs={1}>
                      {convertNumber(scheme.returns.three_years)}
                    </Grid>
                    <Grid xs={1}>
                      {convertNumber(scheme.returns.five_years)}
                    </Grid>
                    <Grid xs={1}>
                      {convertNumber(scheme.returns.since_inception)}
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="3">
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: 2, paddingLeft: 2 }}
                  >
                    <Grid xs={1}>
                      <Typography color="gray">2019</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">2020</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">2021</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">2022</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography color="gray">2023</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: 2, paddingLeft: 2 }}
                  >
                    {year_returns.map((year: number) => {
                      return (
                        <Grid xs={1}>
                          {scheme.year_returns[year] !== "" &&
                          scheme.year_returns[year] !== null ? (
                            <Typography>
                              {scheme.year_returns[year].toFixed(2)}%
                            </Typography>
                          ) : (
                            <Typography sx={{ paddingLeft: "15px" }}>
                              -
                            </Typography>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </TabPanel>
              </TabContext>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Typography variant="h6">Key Metrics </Typography>
              <Grid container spacing={2} sx={{ marginTop: 2, paddingLeft: 2 }}>
                <Grid xs={2}>
                  <Typography color="gray"> Expense Ratio</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography color="gray">Standard Deviation (SD)</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography color="gray">Beta</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography color="gray"> Sharpe Ratio </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: 2, paddingLeft: 2 }}>
                <Grid xs={2}>{convertNumber(scheme.expense_ratio)}</Grid>
                <Grid xs={2}>{convertNumber(scheme.risk_metrics.sd)}</Grid>
                <Grid xs={2}>{convertNumber(scheme.risk_metrics.beta)}</Grid>
                <Grid xs={2}>{convertNumber(scheme.risk_metrics.sharpe)}</Grid>
              </Grid>
              <Typography variant="h6" sx={{ paddingTop: 4 }}>
                Scheme Info
              </Typography>
              <Grid container spacing={2} sx={{ marginTop: 2, paddingLeft: 2 }}>
                <Grid xs={2}>
                  <Typography color="gray">Min Investment Amount</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography color="gray">Benchmark</Typography>
                  <i></i>
                </Grid>
                <Grid xs={2}>
                  <Typography color="gray">Exit Load</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography color="gray">Inception Date</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography color="gray">NAV (In Rs.) </Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography color="gray">AUM (In Crs.)</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: 2, paddingLeft: 2 }}>
                <Grid xs={2}>
                  <Typography>{scheme.min_investment_amount}</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography>{scheme.benchmark}</Typography>
                </Grid>
                <Grid xs={2} sx={{ display: "flex", gap: 1 }}>
                  <Typography>{scheme?.exit_load}</Typography>
                  <Tooltip title={scheme.remark} arrow placement="top">
                    <InfoSharpIcon sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </Grid>
                <Grid xs={2}>
                  <Typography>
                    {new Date(scheme.inception_date).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography>{scheme.nav.toFixed(2)}</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography>{scheme.corpus_in_crs.toFixed()}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {hybrid_funds.includes(scheme.fundType) && (
            <TabContext value={chartTab}>
              <Box>
                <Tabs
                  value={chartTab}
                  onChange={handleChartChange}
                  sx={{ background: "#E8E9EB", marginTop: 3 }}
                >
                  <Tab label="Map Allocation" value="1" />
                  <Tab label="Asset Allocation" value="2" />
                  <Tab label="Rating Allocation" value="3" />
                </Tabs>
              </Box>
              <TabPanel value="1">
                <Card>
                  <CardContent>
                    <Grid xs={3}>
                      <Pie options={optionsPie} data={mapAllocationChart} />
                    </Grid>
                  </CardContent>
                </Card>
              </TabPanel>
              <TabPanel value="2">
                <Card>
                  <CardContent>
                    <Grid xs={3}>
                      <Pie options={optionsPie} data={assetAllocationChart} />
                    </Grid>
                  </CardContent>
                </Card>
              </TabPanel>
              <TabPanel value="3">
                <Card>
                  <CardContent>
                    <Grid xs={3}>
                      <Pie options={optionsPie} data={ratingAllocationChart} />
                    </Grid>
                  </CardContent>
                </Card>
              </TabPanel>
            </TabContext>
          )}

          {debt_funds.includes(scheme.fundType) && (
            <TabContext value={chartTab}>
              <Box>
                <Tabs
                  value={chartTab}
                  onChange={handleChartChange}
                  sx={{ background: "#E8E9EB", marginTop: 3 }}
                >
                  <Tab label="Asset Allocation" value="1" />
                  <Tab label="Rating Allocation" value="2" />
                </Tabs>
              </Box>
              <TabPanel value="1">
                <Card>
                  <CardContent>
                    <Grid xs={3}>
                      <Pie options={optionsPie} data={assetAllocationChart} />
                    </Grid>
                  </CardContent>
                </Card>
              </TabPanel>
              <TabPanel value="2">
                <Card>
                  <CardContent>
                    <Grid xs={3}>
                      <Pie options={optionsPie} data={ratingAllocationChart} />
                    </Grid>
                  </CardContent>
                </Card>
              </TabPanel>
            </TabContext>
          )}

          {!debt_funds.includes(scheme.fundType) &&
            !hybrid_funds.includes(scheme.fundType) &&
            scheme.allocation && (
              <Card>
                <CardContent>
                  <Grid xs={3}>
                    <Pie options={optionsPie} data={mapAllocationChart} />
                  </Grid>
                </CardContent>
              </Card>
            )}
        </Box>
      </div>
    )
  );
};

export default SchemeDetails;
